/*global itemsSectionComponent */
class CustomItemsSectionComponent extends itemsSectionComponent {
    
    getTemplate() {
        return `<div class="row items-view-options" >
                    <itemsBreakcrumb></itemsBreakcrumb>
                    <div class="col-12">
                        <div class="row justify-content-between align-items-center items-view-options mb-2 mb-md-0">
                            <div class="col-auto filters-section">
                                <div class="row">
                                    <homeCategorySectionComponent :only_icons_view="true" :open_by_default="false"></homeCategorySectionComponent>
                                    <categorySectionComponent :categories="false" :open_by_default="false"></categorySectionComponent>
                                </div>
                            </div>
                            <div class="col px-2 mb-2 mb-sm-0 order-items">
                                <orderItemsSelect @reorder="reorderItems"/>
                            </div>
                            <div class="col px-2 view-mode-container">
                                <div class="row justify-content-end view-mode pr-2">
                                    <div class="mr-2 d-none d-sm-block">
                                        <span @click="viewmode='module-small'" :class="{'active':viewmode=='module-small'}">
                                            <i class="fas fa-th fa-2x"></i>
                                        </span>
                                    </div>
                                    <div class="mr-2">
                                        <span @click="viewmode='module'" :class="{'active':viewmode=='module'}">
                                            <i class="fas fa-th-large fa-2x"></i>
                                        </span>
                                    </div>
        
                                    <div class="mr-2">
                                        <span @click="viewmode='list'" :class="{'active':viewmode=='list'}">
                                            <i class="fas fa-th-list fa-2x"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pagination-top d-none d-md-block" v-if="filteredItems.length>0">
                        <div class="row justify-content-center">
                            <paginationComponent :items="filteredItems"></paginationComponent>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <template v-if="isLoading || !readyLoad">
                                  <div class="loading-container">
                                      <div class="loading">
                                          <i class="fas fa-spinner fa-spin fa-3x"></i>
                                      </div>
                                  </div>
                            </template>
                            <template v-else>
                                <template v-if="filteredItems.filter(x=>x.visible).length>0">
                                    <template v-for="item in filteredItems.slice(initItem,lastItem)">
                                        <template v-if="item.visible">
                                            <div :class="itemClassObject" >
                                              <template v-if="viewmode=='list'">
                                                  <itemViewListComponent :item="item.fields? item.fields : item" ></itemViewListComponent>
                                              </template>
                                              <template v-else>
                                                  <itemViewModuleComponent :item="item.fields? item.fields : item" ></itemViewModuleComponent>
                                              </template>
                                            </div>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="container text-center no-items">
                                        <h4>{{tr('Not items for current filters, choose a category')}}</h4>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                    <div class="col-12">
                        <template v-if="filteredItems.length>0">
                            <paginationComponent :items="filteredItems"></paginationComponent>
                        </template>
                    </div>
                </div>`;
    }
}

CustomItemsSectionComponent.registerComponent();